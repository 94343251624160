



































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseButton from '../widgets/BaseButton.vue';

@Component({
  name: 'EmailForm',
  components: {
    BaseTextInput,
    BaseButton
  }
})
export default class EmailForm extends Vue {
  @Prop({ default: null }) readonly initialEmail!: string;

  @Prop({ default: null }) readonly buttonTitle!: string;

  email: string | null = this.initialEmail || this.$route.params.email || null;

  emailSubmit(): void {
    this.$emit('email', {
      email: this.email
    });
  }
}
