






































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import EmailForm
  from '@improve/common-components/src/components/forms/EmailForm.vue';
import BaseCenteredCard
  from '@improve/common-components/src/components/widgets/BaseCenteredCard.vue';

@Component({
  name: 'ForgotPassword',
  components: {
    BaseCenteredCard,
    EmailForm
  }
})
export default class ForgotPassword extends Vue {
  @Action forgotPassword!: ActionMethod;

  email: string | null = null;

  errorHappened = false;

  requestCompleted = false;

  validateAndSubmit(): Promise<boolean> {
    if (this.$refs.obs) {
      const comp: any = this.$refs.obs;
      return comp.validate();
    }
    this.errorHappened = false;
    return Promise.resolve(false);
  }

  async forgotPasswordSubmit(event: { email: string }): Promise<void> {
    if (!(await this.validateAndSubmit())) {
      return;
    }
    this.email = event.email;
    this.forgotPassword({
      email: this.email,
      language: 'en'
    }).then(() => {
      this.requestCompleted = true;
    }).catch(() => {
      this.errorHappened = true;
    });
  }
}
