



































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import styleVariables from '../../styles/export.scss';

@Component({
  name: 'BaseCenteredCard'
})
export default class BaseCenteredCard extends Vue {
  @Prop({ default: null }) readonly title!: string;

  @Prop({ default: '' }) readonly icon?: string;

  get isAdminLogin(): boolean {
    return !!this.$route.query.isAdmin;
  }

  styleVariables = styleVariables;

  adminLoginUrl = `${process.env.VUE_APP_AUTHENTICATION_URL}?redirect=${process.env.VUE_APP_ADMIN_URL}&isAdmin=true`
}
